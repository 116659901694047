import {Card, Col, Descriptions, Input, Row, Tabs} from "antd";
import AdminUserListPage from "./Config/AdminUserListPage";
import AdminSyncPage from "./Config/AdminSyncPage";
import SliderListComponent from "./Slider/SliderListComponent";
import AdminParameterPage from "./Config/AdminParameterPage";
import AdminDovizPage from "./Config/AdminDovizPage";
import AdminDiaSettings from "./Config/AdminDiaSettings";

const AdminConfigComponent = () => {
    return(
        <Row gutter={[10,10]} className={"mt-1"}>
          <Col span={24}>
              <Card title={"Sistem Ayarları"} className={"custom-card"}
              >
                  <Tabs
                      defaultActiveKey="1"
                      style={{marginTop:-20}}
                      destroyInactiveTabPane={false}
                      items={[
                          {
                              label: `Genel Ayarlar`,
                              key: '1',
                              children: <AdminParameterPage/>,
                          },
                          {
                              label: `Kullanıcılar`,
                              key: '2',
                              children: <AdminUserListPage/>,
                          },
                          {
                              label: `Senkronizasyonlar`,
                              key: '3',
                              children: <AdminSyncPage/>,
                          },
                          {
                              label: `Döviz Kurları`,
                              key: '4',
                              children: <AdminDovizPage/>,
                          },
                          {
                              label: `Dia Ayarları`,
                              key: '5',
                              children: <AdminDiaSettings/>,
                          },

                      ]}
                  />
              </Card>
          </Col>
        </Row>
    )
}




export default AdminConfigComponent;