import DashboardBestSellersComponent from "./partials/DashboardBestSellersComponent";
import DashboardNewsProductsComponent from "./partials/DashboardNewsProductsComponent";
import {useContext, useEffect} from "react";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
const DashboardPage = () => {
    const {user} = useContext(AuthContext)
    const navigate = useNavigate();


    useEffect(() => {
        if(user.plasiyer){
            navigate("/admin/dashboard")
        }
    }, []);



    const changePrimaryColor = (color) => {
        const root = document.documentElement;
        root.style.setProperty('--dark-color', color);
    };

    // useEffect(() => {
    //     // Başlangıçta değişkenlerin değerlerini güncelleyebilirsiniz
    //     changePrimaryColor('#23907d'); // Örneğin, yeşil bir renk
    // }, []);
    //

    return (
        <>
            <div className={"section-slider"}>
                <div className={"slider-content"}>
                    <div className={"container"}>
                        <div className={"slider box-shadow"}>
                            <img src={"https://business.hopi.com.tr/media/uhxaptwv/b2b_b2b-nedir_1500x700.jpg"} alt={"Slider 3"}></img>
                        </div>
                        <div className={"fast box-shadow"}>
                            <img src={"https://www.avansas.com/blog/wp-content/uploads/2022/07/b2b-is-modeli-nedir-b2b-is-modelinin-ozellikleri.jpeg"} alt={"Slider 3"}></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"section-new-product"}>
                <DashboardNewsProductsComponent></DashboardNewsProductsComponent>
            </div>

            <div className={"section-rate-product"}>
                <DashboardBestSellersComponent></DashboardBestSellersComponent>
            </div>
        </>
    );
}

export default DashboardPage;