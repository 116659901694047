import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import {useContext} from "react";
import {LayoutContext} from "../../context/LayoutContext";
import {Link} from "react-router-dom";

const FooterLayout = () => {
    const {configJS} = useContext(LayoutContext)
    return (
        <div className={"footer box-shadow"}>
            <div className={"container"}>
                <div className={"company-info"}>
                    <div className={"company-logo"}>
                        {/*<img src={process.env.PUBLIC_URL + '/assets/images/logo-firma.png'}*/}
                        {/*     alt={"Firma Logosu"}></img>*/}
                        <img src={configJS?.company?.logo_url}
                             alt={"Firma Logosu"}></img>
                    </div>

                    <div className={"company-contact"}>
                        <div className={"company-phone"}>
                            <div className={"icon"}><FontAwesomeIcon icon={faPhone}/></div>
                            <div className={"text"}>{configJS?.company?.phone}</div>
                        </div>
                        <div className={"company-adress"}>
                            <div className={"icon"}><FontAwesomeIcon icon={faLocationDot}/></div>
                            <div className={"text"}>{configJS?.company?.address}</div>
                        </div>
                    </div>
                </div>
                <div className={"fast-link"}>
                    <div className={"fast-link-content"}>
                        <Link to={"/sayfa/hakkimizda"} className={"fast-link-item"}>Hakkımızda</Link>
                        <Link to={"/sayfa/iade-ve-iptal"} className={"fast-link-item"}>İade ve İptal</Link>
                        <Link to={"/sayfa/banka-bilgileri"} className={"fast-link-item"}>Banka Bilgileri</Link>
                    </div>

                    <div className={"fast-link-content"}>
                        <Link to={"/sayfa/satis-sozlesmesi"} className={"fast-link-item"}>Satış Sözleşmesi</Link>
                        <Link to={"/sayfa/gizlilik-sozlesmesi"} className={"fast-link-item"}>Gizlilik Sözleşmesi</Link>
                        <Link to={"/sayfa/iletisim"} className={"fast-link-item"}>İletişim</Link>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default FooterLayout