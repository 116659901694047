import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faCartShopping} from "@fortawesome/free-solid-svg-icons";
import {BasketContext} from "../../context/BasketContext";
import {useContext, useEffect, useState} from "react";

import {AuthContext} from "../../context/AuthContext";
import {LayoutContext} from "../../context/LayoutContext";
import HeaederProductSearchComponent from "./partials/header/HeaederProductSearchComponent";
import {Button, Dropdown, message, Space} from "antd";
import Api from "../../services/Api";
import flexCurrency from "../../utils/FlexCurrency";
import {DownOutlined, UserOutlined} from "@ant-design/icons";
import HeaderCurrencyComponent from "./partials/header/HeaderCurrencyComponent";

const HeaderLayout = () => {
    const {products} = useContext(BasketContext)
    const [categories, setCategories] = useState([])
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();
    const {user} = useContext(AuthContext)
    const {configJS} = useContext(LayoutContext)
    const {adminLogin} = useContext(AuthContext)

    const plasiyerModunaGeriDon =() => {
        const resAdmin = JSON.parse(localStorage.getItem("admin"))

        Api.post("/api/Auth/Login", {email:resAdmin.email, password:resAdmin.password}).then(res => {
            if(res.data != null){
                adminLogin(res.data)
            }
        }).catch(err => {
            message.error(err.response.data.detail).then(r=> {})
        })
    }

    return (
        <div className={"header"}>
            <div className={"header-top"}>
                <div className={"container"}>
                    <div className={"header-top-menu"}>
                        Merhaba, {user?.cariAdi}
                    </div>
                    <div className={"header-top-menu"} style={{display:"flex",gap:10}}>
                        <div>
                            {user.bakiye && <span>Bakiye: {flexCurrency(user.bakiye)}</span>}
                        </div>

                        <div>
                            {!user?.plasiyer && <Button onClick={() => plasiyerModunaGeriDon()} size={"small"}>Plasiyer Modu</Button>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"header-content"}>
                <div className={"container"}>
                    <div onClick={() => navigate("/dashboard")}  className={"logo"}>
                        {/*<img src={process.env.PUBLIC_URL + '/assets/images/logo-firma.png'}*/}
                        {/*     alt={"Firma Logosu"}></img>*/}
                        <img src={configJS?.company?.logo_url}
                             alt={"Firma Logosu"}></img>

                    </div>
                   <HeaederProductSearchComponent></HeaederProductSearchComponent>
                    <div className={"user-menu"}>
                        <div className={"user-menu-item"}>
                            <div className={"user-menu-icon"}><FontAwesomeIcon icon={faAddressCard}/></div>
                            <div className={"user-menu-dropdown"}>
                                <Link to={"#"}>Hesabım</Link>
                                <div className={"dropdown-content"}>
                                    <Link to={"/order/management"} className={"dropdown-item"}>Siparişlerim</Link>
                                    <Link to={"/report/account-statament"} className={"dropdown-item"}>Hesap Ekstresi</Link>
                                    <Link to={"/report/account-maturity-analysis"} className={"dropdown-item"}>Vade Analiz Raporu</Link>
                                    <Link to={"/report/account-activity"} className={"dropdown-item"}>Hesap Hareketleri</Link>
                                    <Link to={"/login"} className={"dropdown-item"}>Çıkış Yap</Link>
                                </div>

                            </div>
                        </div>

                        <Link to={"/basket"} className={"user-menu-item"}>
                            <div className={"user-menu-icon"}><FontAwesomeIcon icon={faCartShopping} /></div>
                            <div className={"user-menu-text"}>Sepetim</div>
                            <div className={"user-menu-badge"}>{products.length}</div>
                        </Link>

                    </div>
                </div>

            </div>
            <div className={"header-category-menu"}>
                <div className={"container d-flex"} style={{justifyContent:"space-between"}}>
                    <div className={"menu"}>
                        <div className={"menu-item dropdown"}><Link to={"/products"} className={"category-name"}>Ürünler</Link></div>
                        <div className={"menu-item"}><Link to={"/sayfa/hakkimizda"}>Hakkımızda</Link></div>
                        <div className={"menu-item"}><Link to={"/sayfa/iletisim"}>İletişim</Link></div>
                    </div>
                    <div className={"menu"}>
                        {/*<div className={"menu-item"}><Link to={"/sayfa/iletisim"}>TL</Link></div>*/}
                        <HeaderCurrencyComponent></HeaderCurrencyComponent>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HeaderLayout;